import '../styles/index.scss';
import jquery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/scrollspy';
import Circles from '../../lib/circles-custom';
import InteractiveUCMap from './InteractiveUCMap';
import nav from './nav';
import carousel from './carousel';

/* Carousel */
jquery('[data-ride=carousel]').carousel({
  interval: false
});

/* Map */
if (window.interactiveMap) {
  new InteractiveUCMap(".uc-map-interactive", window.interactiveMap);
}

/* Doughnuts */
jquery(document).ready(function(){
  if(window.onLoadCreateOptions && window.onLoadCreateOptions.length > 0){
    window.onLoadCreateOptions.forEach((opt, idx) => {
      Circles.create(opt);
    });
  }
});
