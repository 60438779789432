import jquery from 'jquery';

export default class InteractiveUCMap {
  constructor(selector, campusData, initialCampus){
    this.element = jquery(selector);
    this.campusData = campusData;

    if(!campusData) {
      throw new Error("Missing Campus Data");
      return;
    }

    initialCampus = initialCampus ? initialCampus : "UC Davis";

    this.registerHandlers();
    this.element.find(`[data-name="${initialCampus}"]`).click();
  }

  registerHandlers() {
    this.element.find('svg .cls-2').on('click focus', this.campusNameClick.bind(this));
  }

  campusNameClick(e) {
    e.preventDefault();
    const $el = jquery(e.currentTarget);
    const campusName = $el.data('name');
    const campusLink = $el.parent().parent().attr('xlink:href');
    const campusData = this.getCampusDataByName(campusName);
    this.element.find('svg .active').removeClass('active');
    $el.parent().parent().addClass('active');
    if(campusData) {
      this.updateCampusInfoBox(campusName, campusLink, campusData);
    }
  }

  getCampusDataByName(name) {
    return this.campusData.hasOwnProperty(name) ? this.campusData[name] : null;
  }

  updateCampusInfoBox(campusName, campusLink, campusData) {
    if(!campusName || !campusData) throw new Error("Missing arguments");

    jquery('#campus-name').html(campusName);
    jquery('#campus-description').html(campusData.description);
    jquery('#campus-students').html(campusData.students);
    jquery('#campus-setting').html(campusData.setting);
    jquery('#campus-fun-fact').html(campusData.funFact);
    jquery('#campus-mascot').html(campusData.mascot.name);
    jquery("#campus-mascot-logo").attr("src", campusData.mascot.logo);
    jquery('#campus-link').attr('href', campusLink);
    jquery('#campus-link span').html(campusName);
  }
}
