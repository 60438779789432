import jquery from 'jquery';

const cleanHero = (_, element) => jquery(element).find('#main-content').removeAttr('id');

jquery('.carousel').each((index, element) => {
  const $this = jquery(element)
  , $tabList    = $this.find('.carousel-indicators')
  , $tabs       = $this.find('.carousel-indicators li')
  , $tabPanels  = $this.find('.carousel-item');

  let $tabPanel
  , $tab
  , i
  , id_title  = 'id_title'
  , id_desc   = 'id_desc';

  $this.attr({ 'tabindex': '0'});
  $tabList.attr('role', 'tablist');
  $tabs.each((_,tab) => jquery(tab).attr({ 'tabindex': '0'}));
  $tabPanels.each(cleanHero);

  for (i = 0; i < $tabPanels.length; i++) {
    $tabPanel = $tabPanels[i];
    $tabPanel.setAttribute('role', 'tabpanel');
    $tabPanel.setAttribute('id', 'tabpanel-' + index + '-' + i);
    $tabPanel.setAttribute('aria-labelledby', 'tab-' + index + '-' + i);
  }

  if (typeof $this.attr('role') !== 'string') {
    $this.attr('role', 'complementary');
    $this.attr('aria-labelledby', id_title);
    $this.attr('aria-describedby', id_desc);
    $this.prepend('<p  id="' + id_desc   + '" class="sr-only">A carousel is a rotating set of images, rotation stops on keyboard focus on carousel tab controls or hovering the mouse pointer over images.  Use the tabs to change the displayed slide.</p>');
    $this.prepend('<h2 id="' + id_title  + '" class="sr-only">Carousel content with ' + $tabPanels.length + ' slides.</h2>');
  }

  for (i = 0; i < $tabs.length; i++) {
    $tab = $tabs[i];

    $tab.setAttribute('role', 'tab');
    $tab.setAttribute('id', 'tab-' + index + '-' + i);
    $tab.setAttribute('aria-controls', 'tabpanel-' + index + '-' + i);

    var tpId = '#tabpanel-' + index + '-' + i;
    var caption = $this.find(tpId).find('h1').text();

    if ((typeof caption !== 'string') || (caption.length === 0)) caption = $this.find(tpId).text();
    if ((typeof caption !== 'string') || (caption.length === 0)) caption = $this.find(tpId).find('h3').text();
    if ((typeof caption !== 'string') || (caption.length === 0)) caption = $this.find(tpId).find('h4').text();
    if ((typeof caption !== 'string') || (caption.length === 0)) caption = $this.find(tpId).find('h5').text();
    if ((typeof caption !== 'string') || (caption.length === 0)) caption = $this.find(tpId).find('h6').text();
    if ((typeof caption !== 'string') || (caption.length === 0)) caption = "no title";

    var tabName = document.createElement('span');
    tabName.setAttribute('class', 'sr-only');
    tabName.innerHTML='Slide ' + (i+1);
    if (caption) tabName.innerHTML += ": " +  caption;
    $tab.appendChild(tabName);
  }
  
  $this.on('slide.bs.carousel', function () {
    var $element = jquery(this);
    $tabs.each(function () {
      var item = jquery(this);
      if(item.hasClass('active')) {
        item.attr({ 'aria-selected': 'true'});
      } else {
        item.attr({ 'aria-selected': 'false'});
      }
    });
  });

  $tabs.on('keydown', (e) => {
    const key = e.which || e.keyCode;
    if (!/(13|32)/.test(key)) return;
    $this.carousel(jquery(e.target).data('slide-to'));
    e.preventDefault();
  });
});
