import jquery from 'jquery';

/* Nav */
const activateMenu = (id, accessibility) => {
  let previous = jquery('.nav-custom-menu.show,.nav-custom-search.show');
  let targetMenu = jquery(id);

  previous.removeClass("show");
  targetMenu.addClass('show');
  if (accessibility) {
    targetMenu.trigger('focus:link');
  }
};

const closePreviousMenu = () => {
  let previous = jquery('.nav-custom-menu.show');
  previous.removeClass("show");
};

jquery('.nav-custom-menu').each((idx,el) => {
  const $el = jquery(el);
  const menu = $el.attr('id');
  const $trigger = jquery(`.nav-menu-open[data-menu="${menu}"]`);
  const $focus = $el.find('.nav-custom-menu-col:first-child .nav-custom-menu-link-group:first-child > p > a');
  const $close = $el.find('.nav-menu-close');
  $el.on('focus:link', (e) => {
    $focus.focus();
    $close.addClass('show');
  });
  $focus.on('keydown', (e) => {
    if (e.shiftKey && e.key === 'Tab') {
      e.preventDefault();
      $close.focus();
    }
  });
  $close.on('click', (e) => {
    closePreviousMenu();
    $trigger.focus();
  });
  $close.on('keydown', (e) => {
    if (e.key === 'Tab') {
      $focus.focus();
    }
  });
});

jquery('[data-menu-toggle]').on('mouseenter', (e) => {
  activateMenu(jquery(e.currentTarget).data('menu-toggle'));
});
jquery('.nav-menu-open').on('click', (e) => {
  activateMenu(jquery(e.currentTarget).prev().data('menu-toggle'),true);
});

jquery('.navbar-items-menu .nav-item').on('mouseleave', (e) => {
  if (jquery('.nav-custom:hover').length == 0) {
    closePreviousMenu();
  }
});

jquery('.nav-custom-menu').on('mouseleave', (e) => {
  if (jquery('.nav-item:hover').length == 0) {
    closePreviousMenu();
  }
});

jquery('#search-menu-btn').on('click', () => {
  let search = jquery('#search-menu');
  search.toggleClass('show');
  if (search.css('display') === 'block') {
    search.find('input[type=text]').focus();
  }
});
